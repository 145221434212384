import { connect } from 'react-redux';

import RangeInputSingle from './rangeInput';
import RadioInputsSingle from './radioInputs';
import DisplayMonthyRateSingle from './displayMonthlyRate';
import Overlay from './overlay';
import LinkButton from './linkButton';

function mapStateToProps(state) {
  return {
    widget: state.FrontPageWidget,
  };
}

const DisplayMonthyRate = connect(mapStateToProps)(DisplayMonthyRateSingle);
const RangeInput = connect(mapStateToProps)(RangeInputSingle);
const RadioInputs = connect(mapStateToProps)(RadioInputsSingle);

export { RangeInput, RadioInputs, DisplayMonthyRate, Overlay, LinkButton };

export default RangeInput;
