export default function redirect(state) {
  const data = {
    amount: state.range.amount,
    years: state.range.years,
    amortation: state.amortation,
  };
  /* Cache data to localstorage */
  sessionStorage.setItem('ansokan', JSON.stringify(data));
  // Redirect user
  window.location.href = '/laneansokan/';
  return state;
}
