import { defineMessages } from 'react-intl';

export default defineMessages({
	headline: {
		id: 'WIDGET.HEADLINE',
		defaultMessage: 'Calculate your loan',
	},
	loanAmount: {
		id: 'WIDGET.LABEL.LOANAMOUNT',
		defaultMessage: 'Choose loan amount',
	},
	loanYears: {
		id: 'WIDGET.LABEL.LOANYEARS',
		defaultMessage: 'Select the number of years',
	},
	loanInterest: {
		id: 'WIDGET.LABEL.LOANINTEREST',
		defaultMessage: 'Choose sample interest rate',
	},
	amortizationFree: {
		id: 'WIDGET.LABEL.AMORTIZATIONFREE',
		defaultMessage: 'Amortization free first year',
	},
	btnSubmit: {
		id: 'WIDGET.BUTTON.SUBMIT',
		defaultMessage: 'Apply for your loan',
	},
	modalLink: {
		id: 'WIDGET.MODAL.LINK',
		defaultMessage: 'Heres how we have calculated',
	},
	modalLegal: {
		id: 'WIDGET.MODAL.LEGAL',
		defaultMessage: 'A whole lotta text',
	},
	modalClose: {
		id: 'WIDGET.MODAL.CLOSE',
		defaultMessage: 'Close',
	},
	asideHeadline: {
		id: 'WIDGET.ASIDE.HEADLINE',
		defaultMessage: 'You pay each month',
	},
	asideMonthlyCost: {
		id: 'WIDGET.ASIDE.MONTHLYCOST',
		defaultMessage: 'The monthly cost is calculated at an interest rate on',
	},
	asideMonthlyCost2: {
		id: 'WIDGET.ASIDE.MONTHLYCOST2',
		defaultMessage: ' and an effective interest rate on',
	},
	asideFreeMonth_part1: {
		id: 'WIDGET.ASIDE.FREEMONTH.PART1',
		defaultMessage: 'during the first year, thereafter',
	},
	asideFreeMonth_part2: {
		id: 'WIDGET.ASIDE.FREEMONTH.PART2',
		defaultMessage: 'per month',
	},
});
