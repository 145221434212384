import _ from 'lodash';

import { UPDATE_AMOUNT, DIRECT_USER, SHOW_DRAWER } from '../actions/types';
import { updatePayment, redirect } from '../utils';

// Add
// console.log(updateMonthlyPayment);
// at the end of updatePayment to recalculate monthly and firstYear
// if you change any of the other values.
const initialState = {
  monthly: 1954,
  firstYear: 997,
  range: {
    amount: 150000,
    years: 10,
    tax: 7.95,
  },
  amortation: true,
  showDrawer: false,
};

function frontPagetWidget(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AMOUNT:
      return updatePayment(_.merge({}, state, action.data));
    case DIRECT_USER:
      return redirect(state);
    case SHOW_DRAWER:
      return _.merge({}, state, action.data);
    default:
      return state;
  }
}

export default frontPagetWidget;
