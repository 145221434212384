import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NumericFormat } from 'react-number-format';

import translation from './view/translation';

export default function FirstYearPayment(props) {
  const { display, widget } = props;
  if (!display) return null;
  return (
    <div className="o-grid__cell o-grid__cell--width-100">
      <p className="c-text u-large">
        <FormattedMessage {...translation.asideFreeMonth_part1} />&nbsp;
        <NumericFormat
          value={widget.monthly}
          displayType="text"
          thousandSeparator=" "
          suffix=" kr"
          decimalScale={0}
        /> <FormattedMessage {...translation.asideFreeMonth_part2} />
      </p>
    </div>
  );
}
FirstYearPayment.propTypes = {
  display: PropTypes.bool.isRequired,
  widget: PropTypes.shape().isRequired,
};
