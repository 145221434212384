import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { RangeInput, RadioInputs, DisplayMonthyRate, Overlay, LinkButton } from '../';
import translation from './translation';

const getMaxYears = (amount) => {
  if (amount <= 50000) {
    return 6;
  }
  else if (amount <= 150000) {
    return 10;
  }
  return 15;
};

class View extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDrawer = this.handleDrawer.bind(this);
    const { widget } = props;
    this.maxYears = getMaxYears(widget.range.amount);
  }
  handleSubmit(event) {
    event.preventDefault();
    const { dispatchDirectUser } = this.props;
    dispatchDirectUser();
  }
  handleDrawer(action) {
    const { dispatchShowDrawer } = this.props;
    const val = _.setWith({}, '[showDrawer]', (action === 'OPEN'), Object);
    dispatchShowDrawer(val);
  }
  amountChanged = (val) => {
    const { dispatchUpdateAmount } = this.props;
    const amount = val['range']['amount'];
    this.maxYears = getMaxYears(amount);
    dispatchUpdateAmount(val);
  }
  render() {
    const { widget, dispatchUpdateAmount, intl } = this.props;
    const toggleDrawer = (widget.showDrawer) ? ' o-drawer--visible' : '';
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="c-card c-card--drawered">
          <div className="c-card__item u-window-box--large">
            <div className="o-grid o-grid--wrap">
              <div className="o-grid__cell o-grid__cell--width-100">
                <h3><FormattedMessage {...translation.headline} /></h3>
              </div>
              <div className="o-grid__cell o-grid__cell--no-gutter o-grid__cell--width-100@small o-grid__cell--width-50@large">
                <RangeInput
                  widgetType="amount"
                  dispatchChange={this.amountChanged}
                  label={intl.formatMessage(translation.loanAmount)}
                  inputName="amount"
                  rangeMin={20000}
                  rangeMax={500000}
                  rangeStep={1000}
                />
                <RangeInput
                  widgetType="years"
                  dispatchChange={dispatchUpdateAmount}
                  label={intl.formatMessage(translation.loanYears)}
                  inputName="years"
                  rangeMin={2}
                  rangeMax={this.maxYears}
                  rangeStep={1}
                />
                <RangeInput
                  widgetType="tax"
                  dispatchChange={dispatchUpdateAmount}
                  label={intl.formatMessage(translation.loanInterest)}
                  inputName="tax"
                  rangeMin={6.95}
                  rangeMax={16.95}
                  rangeStep={0.01}
                />
                <RadioInputs dispatchChange={dispatchUpdateAmount} />
              </div>
              <div className="o-grid__cell o-grid__cell--no-gutter o-grid__cell--width-100@small o-grid__cell--width-50@large">
                <DisplayMonthyRate />
                <div className="o-grid o-grid--wrap">
                  <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-70@large u-letter-box--medium">
                    <input
                      type="submit"
                      value={intl.formatMessage(translation.btnSubmit)}
                      className="c-button c-button--rounded c-button--brand c-button--block u-large"
                    />
                  </div>
                  <div className="o-grid__cell o-grid__cell--width-100">
                    <p>
                      <LinkButton onClick={() => { this.handleDrawer('OPEN'); }}>
                        <FormattedMessage {...translation.modalLink} />
                      </LinkButton>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Overlay show={widget.showDrawer} />
          <div className={`o-drawer o-drawer--bottom o-drawer--animated u-highest${toggleDrawer}`}>
            <div className="u-window-box--large">
              <p className="c-text u-small"><FormattedMessage {...translation.modalLegal} /></p>
              <div className="o-grid">
                <div
                  className="o-grid__cell--width-100@xsmall o-grid__cell--width-33@medium o-grid__cell--offset-33@medium u-letter-box--large"
                  style={{ paddingBottom: 0 }}
                >
                  <button
                    type="button"
                    className="c-button c-button--rounded c-button--block c-button--ghost-brand u-small"
                    onClick={() => { this.handleDrawer('CLOSE'); }}
                  >
                    <FormattedMessage {...translation.modalClose} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

View.propTypes = {
  dispatchUpdateAmount: PropTypes.func.isRequired,
  dispatchDirectUser: PropTypes.func.isRequired,
  dispatchShowDrawer: PropTypes.func.isRequired,
  widget: PropTypes.shape().isRequired,
  intl: PropTypes.any.isRequired,
};

export default View;
