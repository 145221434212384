import _ from 'lodash';

// Monthly payment
function calcPayment(tax, loanAmount, years) {
  const interestRate = tax/100;
  const numberOfPeriods = 12 * years;

  var v = (1+(interestRate/12));
  var t = -numberOfPeriods;
  return (loanAmount*interestRate/12)/(1-Math.pow(v,t));

//  return (interestRate * loanAmount) / (1.0 - ((1 + interestRate) ** (-numberOfPeriods)));
}

function calcFirstYear(loanAmount, tax) {
  return (loanAmount * tax / 100) / 12;
}

function updatePayment(newState) {
  const parse = {
    amount: parseInt(newState.range.amount, 10),
    years: parseInt(newState.range.years, 10),
    tax: parseFloat(newState.range.tax),
  };
  let updateMonthlyPayment = {};
  let monthlyPayment = 0;
  // Calculate loan amount
//  const loanAmount = parse.amount;
  const loanAmount = 429 + parse.amount;
  if (newState.amortation) {
    // Calculate the monthly payment
    monthlyPayment = calcPayment(parse.tax, loanAmount, parse.years - 1);
    // Mergable object
    updateMonthlyPayment = { monthly: monthlyPayment, firstYear: calcFirstYear(loanAmount, parse.tax) };
  } else {
    // Calculate the monthly payment
    monthlyPayment = calcPayment(parse.tax, loanAmount, parse.years);
    // Mergable object
    updateMonthlyPayment = { monthly: monthlyPayment };
  }
  // Merge everything together
  return _.merge({}, newState, updateMonthlyPayment);
}

export default updatePayment;
