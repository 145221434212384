import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose } from 'redux';
import { IntlProvider } from 'react-intl';
import langSv from './i18n/sv-SE.json';
import reducers from './reducers';
import App from './App';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
const store = createStore(reducers, composeEnhancers());

render(
  <Provider store={store}>
    <IntlProvider locale="sv" messages={langSv}>
      <App />
    </IntlProvider>
  </Provider>,
  document.getElementById('root'),
);
