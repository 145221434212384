import React from 'react';
import PropTypes from 'prop-types';

export default function Textfield(props) {
  return <input type={props.type} value={props.value} className="c-field c-field--input-override" onChange={props.onChange} />;
}

Textfield.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
