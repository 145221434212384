import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import translation from './view/translation';

class RadioInputs extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const { dispatchChange } = this.props;
    const val = _.setWith({}, '[amortation]', event.target.checked, Object);
    dispatchChange(val);
  }
  render() {
    const { widget } = this.props;
    return (
      <div className="o-grid o-grid--full">
        <div className="o-grid__cell">
          <div className="o-form-element o-form-element--brand">
            <label className="c-label c-field c-field--choice c-field--custom c-field--bold">
              <input type="checkbox" name="amortation" checked={widget.amortation} onChange={this.handleChange} />
              <span className="c-field-custom__checkmark">&nbsp;</span>
              <FormattedMessage {...translation.amortizationFree} />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

RadioInputs.propTypes = {
  widget: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  dispatchChange: PropTypes.func.isRequired,
};

export default RadioInputs;
