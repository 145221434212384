import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import View from './view';
import { updateAmount, directUser, showDrawer } from '../../actions';

function mapStateToProps(state) {
  return {
    widget: state.FrontPageWidget,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateAmount: (value) => { dispatch(updateAmount(value)); },
    dispatchDirectUser: () => { dispatch(directUser()); },
    dispatchShowDrawer: (value) => { dispatch(showDrawer(value)); },
  };
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
);

export default enhance(View);
