import 'rc-slider/assets/index.css';
// import 'rc-tooltip/assets/bootstrap.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NumericFormat } from 'react-number-format';
import Slider from 'rc-slider';

import Textfield from './textField';

class RangeInput extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleNumberFormatChange = this.handleNumberFormatChange.bind(this);
    this.handleNumberFormatChange = _.debounce(this.handleNumberFormatChange, 1000);
  }

  handleChange(event) {
    const { dispatchChange, widgetType } = this.props;
    const val = _.setWith({}, `[range][${widgetType}]`, event, Object);
    //console.log('handleChange: ' + val);
    dispatchChange(val);
  }

  handleNumberFormatChange(values) {
    const { dispatchChange, widgetType, rangeMin, rangeMax } = this.props;
    const { floatValue } = values;
    const newValue = !floatValue || floatValue < rangeMin ? rangeMin : (floatValue > rangeMax ? rangeMax : floatValue);
    const val = _.setWith({}, `[range][${widgetType}]`, newValue, Object);
    //console.log('handleNumberFormatChange');
    dispatchChange(val);
  }

  render() {
    const {
      label, inputName, widget: { range }, widgetType, rangeMin, rangeMax, rangeStep,
    } = this.props;
    const isNumbericString = true;
    const suffixType = (type) => {
      switch (type) {
        case 'years':
          return ' år';
        case 'tax':
          return ' %';
        default:
          return ' kr';
      }
    };
    return (
      <div className="o-grid o-grid--full">
        <div className="o-grid__cell">
          <div className="o-grid o-grid--wrap o-grid--no-gutter">
            <div className="o-grid__cell">
              <div className="u-letter-box--small">{label}</div>
            </div>
            <div className="o-grid__cell o-grid__cell--width-100@xsmall o-grid__cell--width-30@small">
              <NumericFormat
                value={range[widgetType]}
                thousandSeparator=" "
                suffix={suffixType(widgetType)}
                decimalSeparator=","
                customInput={Textfield}
                valueIsNumericString={isNumbericString}
                onValueChange={this.handleNumberFormatChange}
              />
            </div>
          </div>
        </div>
        <div className="o-grid__cell">
          <div className="c-range__wrapper">
            <div>
              <Slider min={rangeMin} max={rangeMax} step={rangeStep} onChange={this.handleChange} className='c-range u-small' value={range[widgetType]} />
            </div>
            <div className="c-range__track">
              <div className="c-range__track__item c-range__track__item--first">
                <NumericFormat
                  value={rangeMin}
                  suffix={suffixType(widgetType)}
                  thousandSeparator=" "
                  decimalSeparator=","
                  valueIsNumericString={isNumbericString}
                  displayType="text"
                />
              </div>
              <div className="c-range__track__item c-range__track__item--last">
                <NumericFormat
                  value={rangeMax}
                  suffix={suffixType(widgetType)}
                  thousandSeparator=" "
                  decimalSeparator=","
                  valueIsNumericString={isNumbericString}
                  displayType="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RangeInput.propTypes = {
  widget: PropTypes.shape().isRequired,
  widgetType: PropTypes.string.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  rangeMin: PropTypes.number.isRequired,
  rangeMax: PropTypes.number.isRequired,
  rangeStep: PropTypes.number.isRequired,
};

export default RangeInput;
