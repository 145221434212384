import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NumericFormat } from 'react-number-format';

import translation from './view/translation';
import FirstYearPayment from './firstYearPayment';

class DisplayMonthyRate extends PureComponent {
    /*
     * Based on a Java implementation that is based on the IRR
     * function in Excel.
     * This is a "brute force" way of calculating irr called the
     * Newton-Raphson method. It cannot be used without a fallback
     * because it's non-deterministic. Excel uses an unknown secret fallback.
     */
    irr(cashFlows) {
        const calcNpv = function(cashFlows, rate) {
            let npv = 0;
            for (let i = 1; i < cashFlows.length; i++) {
                npv += cashFlows[i] / Math.pow(1+rate, i);
            }
            return npv;
        };
        
        const MIN_DIFF = 1e-7;
        const MAX_ITERATIONS = 1000;
        const flowOut = cashFlows[0];
        let minValue = 0;
        let maxValue = 1;
        let testValue = 0;
        let iterations = MAX_ITERATIONS;
        
        while (iterations > 0) {
            testValue = (minValue+maxValue) / 2;
            const npv = calcNpv(cashFlows, testValue);
            if (Math.abs(flowOut+npv) < MIN_DIFF) {
                break;
            }
            else if (Math.abs(flowOut) > npv) {
                maxValue = testValue;
            }
            else {
                minValue = testValue;
            }
            iterations--;
        }
        return testValue;
    }

	calculateEffectiveRate() {
		const { widget } = this.props;
		const amount = widget.range.amount + 429;
		const firstYear = widget.amortation ? widget.firstYear : widget.monthly; 
		const monthly = widget.monthly;
		const numberOfMonths = widget.range.years * 12;
		const initialCashflow = -amount;

		let cashFlows = [initialCashflow];
		for (let i = 0; i < numberOfMonths; i++) {
			if (i < 12) {
				cashFlows.push(firstYear);
			}
            else {
				cashFlows.push(monthly);
			}
		}

		if (!initialCashflow || cashFlows.length === 1) {
			return;
		}

        const irr = this.irr(cashFlows);
        const effectiveInterestRate = Math.pow(1 + irr, 12) - 1;

		return effectiveInterestRate;
	}

	render() {
		const effectiveInterestRatePercent = Math.round(this.calculateEffectiveRate() * 10000) / 100;
		const { widget } = this.props;
		const printPayment = widget.amortation ? widget.firstYear : widget.monthly;
		const isNumbericString = true;
		return (
			<div className="o-grid o-grid--wrap">
				<div className="o-grid__cell o-grid__cell--width-100">
					<p className="c-text"><FormattedMessage {...translation.asideHeadline} /></p>
					<div className="c-text u-text-important--prio-highest">
						<NumericFormat
							value={printPayment}
							displayType="text"
							thousandSeparator=" "
							suffix=" kr"
							decimalScale={0}
						/>
					</div>
				</div>
				<FirstYearPayment display={widget.amortation} widget={widget} />
				<div className="o-grid__cell o-grid__cell--width-100">
					<p className="c-text c-text--quiet u-small">
						<FormattedMessage {...translation.asideMonthlyCost} />&nbsp;
						<NumericFormat
							value={widget.range.tax}
							decimalSeparator=","
							decimalScale={2}
							valueIsNumericString={isNumbericString}
							displayType="text"
							suffix="%"
						/>

						<FormattedMessage {...translation.asideMonthlyCost2} />&nbsp;
						<NumericFormat
							value={effectiveInterestRatePercent}
							decimalSeparator=","
							decimalScale={2}
							valueIsNumericString={isNumbericString}
							displayType="text"
							suffix="%"
						/>
						*
					</p>
				</div>
			</div>
		);
	}
}

DisplayMonthyRate.propTypes = {
	widget: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default DisplayMonthyRate;
