import { UPDATE_AMOUNT, DIRECT_USER, SHOW_DRAWER } from './types';

export function updateAmount(data = {}) {
  return {
    type: UPDATE_AMOUNT,
    data,
  };
}

export function directUser() {
  return {
    type: DIRECT_USER,
  };
}

export function showDrawer(data = {}) {
  return {
    type: SHOW_DRAWER,
    data,
  };
}

export default updateAmount;
