import React from 'react';
import PropTypes from 'prop-types';

function LinkButton(props) {
  return (
    // eslint-disable-next-line
    <span onClick={props.onClick} className="c-link c-link--brand">{props.children}</span>
  );
}
LinkButton.protoType = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

export default LinkButton;
