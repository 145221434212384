import React from 'react';
import PropTypes from 'prop-types';

function Overlay(props) {
  const { show } = props;
  if (!show) return null;
  return (
    <div className="c-overlay c-overlay--dismissable">&nbsp;</div>
  );
}
Overlay.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Overlay;
